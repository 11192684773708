body {
    margin: 0;
    overflow-x: hidden;
    /*overflow-y: scroll;*/
    background: #f1f1f1;
    padding: 15px 12px;
}

._wrapper {
    position: relative;
    min-width: 100%;
    min-height: calc(100vh - 20px);
    border-radius: 6px;
    background: #fff;
    overflow: hidden;
    box-shadow: 0rem 0.25rem 0.375rem -0.125rem rgba(26, 26, 26, 0.20);

    &.preview-section {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    &.loading {
        ._loading {
            opacity: 1;
            visibility: visible
        }
    }

    &.loading--opacity {
        ._loading {
            background: rgba(255, 255, 255, 1) !important;
        }
    }

    ._loading {
        background: rgba(255, 255, 255, 1);
        height: 100%;
        width: 100%;
        position: fixed;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: 0.2s;
        opacity: 0;
        visibility: hidden;
        z-index: 99999999;
        top: 0;

        svg {
            width: 80px;
            height: 80px;
            animation: bounchTop 0.7s infinite linear;
            position: relative;
        }
    }
}

.builder-preview {
    .preview-group {
        &.d-none {
            display: none !important;
        }
    }

    .section {
        /*position: relative;*/
        line-height: 0;

        &.section-preview {
            &:before {
                content: "" !important;
                display: none !important;
            }
        }

        /* &:before {
            pointer-events: none;
            content: "";
            position: absolute;
            width: calc(100% - 4px);
            height: calc(100% - 4px);
            border: 2px solid transparent;
            transition: all 0.4s;
            z-index: 0;
        } */

        /* &.active {
            &:before {
                border-color: #005BD3;
                z-index: 999;
            }

        } */

        /* &.move {
            &:before {
                width: calc(100% - 6px);
                height: calc(100% - 6px);
                border: 3px solid #005BD3;
                z-index: 999;
            }
        } */

        &.hidden {
            display: none;
        }

        /* &:hover {
            &:before {
                pointer-events: none;
                border-color: #005BD3;
                z-index: 9999999;
            }
        } */
    }

    #item_1 {
        .inner {
            background: rgb(5, 212, 185);
        }
    }

    #item_2 {
        .inner {
            background: rgb(219, 4, 69);
        }
    }

    #item_3 {
        .inner {
            background: rgb(59, 185, 8);
        }
    }

    #item_4 {
        .inner {
            background: rgb(215, 238, 4);
        }
    }

    #item_5 {
        .inner {
            background: rgb(48, 51, 23);
            color: #fff;
        }
    }

    #item_6 {
        .inner {
            background: rgb(238, 4, 199);
        }
    }
}

.fake-element {
    --container-gutter: 30px;
    --container-max-width: calc(var(--page-width) - var(--container-gutter) * 2);
    background-color: #fff !important;

    .fake-element__inner {
        margin-inline-start: max(var(--container-gutter), 50% - var(--container-max-width) / 2);
        margin-inline-end: max(var(--container-gutter), 50% - var(--container-max-width) / 2);

        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    ._fake-1 {
        display: block !important;
        border-radius: 20px;
        background: #F1F1F1;
        height: 12px;
        width: 60px;
    }

    ._fake-2 {
        display: block !important;
        border-radius: 20px;
        background: #F1F1F1;
        height: 10px;
        width: 100px;

        &.--style-1 {
            width: 60px;
        }

        &.--style-2 {
            width: 90px;
        }
    }

    &.--header {
        padding-top: 16px;
        padding-bottom: 16px;
        border-bottom: 1px solid #eee;
    }

    &.--footer {
        padding-top: 48px;
        padding-bottom: 48px;
        border-top: 1px solid #eee;
        border-bottom: 1px solid #eee;
        align-items: start;
        position: relative;
        z-index: 1;

        .fake-element__inner {
            align-items: start;
        }
    }

    ._logo {
        color: #B5B5B5;
        font-size: 18px;
        font-weight: 650;
        line-height: 20px;
        min-width: 150px;
    }

    ._actions {
        display: flex;
        gap: 24px;
        margin-left: 17px;
    }

    ._icons {
        display: flex;
        gap: 12px;
        min-width: 165px;
        justify-content: end;

        ._icon-item {
            padding: 8px;
            border-radius: 50%;
            background: #F1F1F1;
            line-height: 0;

            svg {
                width: 20px;
                height: 20px;

                path {
                    fill: #CCCCCC;
                }
            }
        }
    }
}

.empty {
    text-align: center;
    padding: 50px 40px 100px 40px;

    img {}

    h3 {
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 650;
        line-height: 20px;
        color: #303030;
        margin-bottom: 6px;
        margin-top: 0;
    }

    p {
        font-family: Inter;
        font-size: 12px;
        font-style: normal;
        font-weight: 450;
        line-height: 16px;
        color: #303030;
        margin-top: 0;
        margin-bottom: 16px;
    }

    button {
        border-radius: 8px;
        background: #303030;
        padding: 6px 12px;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: 16px;
        color: #fff;
        position: relative;
        border: transparent;
        cursor: pointer;
        opacity: 0.9;
        font-family: 'Inter';

        &:hover {
            opacity: 1;
        }

        &:before {
            content: "";
            border-radius: 9px;
            background: linear-gradient(180deg, rgba(255, 255, 255, 0.07) 82.14%, rgba(255, 255, 255, 0.15) 94.44%);
            box-shadow: 0px 2px 0px 0px rgba(255, 255, 255, 0.20) inset, 2px 0px 0px 0px rgba(255, 255, 255, 0.20) inset, -2px 0px 0px 0px rgba(255, 255, 255, 0.20) inset, 0px -1px 0px 1px #000 inset, 0px 1px 0px 0px #000 inset;
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
        }

        &.add-template {
            margin-right: 10px;
            background: #fff;
            color: #303030;

            &:before {
                box-shadow: 0px -1px 0px 0px #B5B5B5 inset, -1px 0px 0px 0px #E3E3E3 inset, 1px 0px 0px 0px #E3E3E3 inset, 0px 1px 0px 0px #E3E3E3 inset;
            }
        }
    }
}

@media screen and (max-width: 900px) {
    .fake-element {
        &.--header {
            ._actions {
                margin-left: 25px;

                ._fake-1 {

                    &:nth-child(1),
                    &:nth-child(4) {
                        display: none !important;
                    }
                }
            }
        }

        &.--footer {
            ._column {
                &:nth-child(3) {
                    display: none !important;
                }
            }
        }
    }
}

@media screen and (max-width: 767px) {
    .fake-element {
        --container-gutter: 15px;
    }
}

@media screen and (max-width: 500px) {
    .fake-element {
        &.--header {
            ._actions {
                ._fake-1 {
                    display: none !important;
                }
            }

            ._icons {
                min-width: unset;
            }
        }

        &.--footer {
            .fake-element__inner {
                display: block !important;
            }

            ._column {
                margin-top: 36px;

                &:nth-child(2) {
                    display: none !important;
                }

                ._fake-1 {
                    width: 130px;
                }

                ._fake-2 {

                    &:nth-child(2),
                    &:nth-child(4) {
                        width: 230px;
                    }

                    &:nth-child(3) {
                        width: 150px;
                    }

                    &:nth-child(5) {
                        width: 200px;
                    }
                }
            }
        }
    }
}

@keyframes bounchTop {
    0% {
        top: 0;
    }

    50% {
        top: -15px;
    }

    100% {
        top: 0;
    }
}

hover-controls {
    &.hidden {
        opacity: 0;
        visibility: hidden;
        pointer-events: none;
    }
}